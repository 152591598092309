import * as React from "react"
import Sidebar from "../components/sidebar";
import Footer from "../components/footer";
import Header from "../components/header";

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Privacy</title>
      <Header />
      <div className="w-full max-w-7xl mx-auto mt-6">
        <div className="flex flex-col-reverse lg:flex-row items-start">
          <Sidebar />
          <div className="mx-4 lg:mx-8 text-gray-700 text-justify">
            <h2 className="text-4xl py-6 text-gray-600">Privacy Policy</h2>
            <p>Halaman ini berisi informasi tentang data yang kami kumpulkan dan bagaimana kami memproses informasi tersebut</p>
            <div className="text-justify">
              <h3 className="text-blue-400 text-xl py-4 lg:text-3xl lg:py-6" id="data-type">Jenis Data yang Kami Kumpulkan</h3>
              <div className="border-blue-400 border-l-2 pl-4 ml-2 lg:border-l-4 lg:pl-6">
                <h4 className="font-bold">Data Pribadi</h4>
                <p>
                  Kami mengoleksi informasi pribadi yang digunakan untuk menghubungi atau mengidentifikasi diri Anda, data
                  yang kami kumpulkan antara lain sebagai berikut; Alamat email, Nomor Telepon, Alamat.
                </p>
                <br />
                <h4 className="font-bold">Semua yang Anda Bagikan</h4>
                <p>
                  Kami mengoleksi semua konten, komunikasi dan informasi lainnya yang Anda bagikan ketika Anda menggunakan
                  produk kami. Hal ini dapat termasuk informasi tentang konten yang anda berikan seperti lokasi pada foto
                  atau tanggal file tersebut dibuat.
                </p>
                <br />
                <h4 className="font-bold">Informasi Perangkat</h4>
                <p>
                  Kami mengoleksi informasi tentang komputer, handphone dan perangkat lainnya yang anda gunakan untuk
                  mengakses layanan kami.
                </p>
                <p>
                  Informasi yang kami kumpulkan dari perangkat anda meliputi:
                </p>
                <br />
                <ul className="ml-8 list-disc">
                  <li>Atribut Perangkat: Informasi seperti jenis sistem operasi dan perangkat keras.</li>
                  <li>Identifiers: ID unik yang berasal dari perangkat Anda, IP Address.</li>
                  <li>
                    Data dari perangkat: Informasi yang Anda izinkan kami untuk mendapatkannya melalui perangkat Anda
                    seperi akses ke lokasi GPS, Camera dan penyimpanan.
                  </li>
                  <li>Cookie</li>
                </ul>
              </div>
              <h3 className="text-green-400 text-xl py-4 lg:text-3xl lg:py-6" id="how-info-used">Bagaimana Informasi Digunakan</h3>
              <div className="border-green-400 border-l-2 pl-4 ml-2 lg:border-l-4 lg:pl-6">
                <p>Kami menggunakan informasi yang kami kumpulkan dari Anda untuk tujuan yang dijelaskan dibawah ini:</p>
                <br />
                <ul className="ml-8 list-disc">
                  <li>Penyediaan layanan terkait permintaan Anda untuk memproses serta menyelesaikan seluruh transaksi yang ada.</li>
                  <li>Menanggapi complain serta pengajuan untuk pertanyaan dan keluhan pelanggan atas layanan kami.</li>
                  <li>Untuk mengingkatkan pelayanan kami dan pengalaman Anda menggunakan layanan kami.</li>
                  <li>Meningkatkan kemudahan serta fungsionalitas layanan kami.</li>
                  <li>Menyediakan Analisa data yang telah dihasilkan dari layanan kami.</li>
                  <li>Untuk tujuan lainnya dari informasi yang dikumpulkan.</li>
                </ul>
              </div>
              <h3 className="text-red-400 text-xl py-4 lg:text-3xl lg:py-6" id="how-to-remove-data">Bagaimana Saya Menghapus Data Milik Saya</h3>
              <div className="border-red-400 border-l-2 pl-4 ml-2 lg:border-l-4 lg:pl-6">
                <p>
                  Jika Anda ingin kami untuk menghapus informasi personal dari sistem kami, silahkan hubungi kami dari
                  di (email) dengan permintaan kepada kami untuk meghapus informasi pribadi dari database kami. Kami
                  akan menggunakan upaya yang wajar secara komersil untuk menghormati permohonan anda. Namun, kami
                  akan menyimpan salinan arsip catatan anda seperti yang dipersyaratkan oleh hukum atau untuk tujuan
                  bisnis yang sah lainnya.
                </p>
              </div>
              <h3 className="text-yellow-400 text-xl py-4 lg:text-3xl lg:py-6" id="privacy-change">Perubahan Kebijakan Privasi</h3>
              <div className="border-yellow-400 border-l-2 pl-4 ml-2 lg:border-l-4 lg:pl-6">
                <p>
                  Kami mungkin akan melakukan perubahan terhadap kebijakan privasi ini. Kami akan menginformasikan kepada
                  Anda jika ada perubahan kebijakan privasi melalui kontak yang tersedia dari profil Anda.
                </p>
              </div>
              <br />
              <p>
                Jika anda memiliki pertanyaan Anda dapat menghubungi kami melalui
                <a className="text-green-500" href="mailto:sthiradev@sthira-nusa.co.id">
                  sthiradev@sthira-nusa.co.id
                </a>
              </p>
            </div>
          </div>
          <div className="lg:sticky top-0 mx-4 lg:ml-8 py-2 lg:py-4 text-gray-700 lg:min-w-max">
            <p className="p-2 font-semibold text-lg">Table of Contents</p>
            <ul className="text-sm list-none text-gray-600">
              <li className="p-2">
                <a href="#data-type">Jenis Data yang Kami Kumpulkan</a>
              </li>
              <li className="p-2">
                <a href="#how-info-used">Bagaimana Informasi Digunakan</a>
              </li>
              <li className="p-2">
                <a href="#how-to-remove-data">Bagaimana Saya Menghapus Data Milik Saya</a>
              </li>
              <li className="p-2">
                <a href="#privacy-change">Perubahan Kebijakan Privasi</a>
              </li>
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    </main>
  )
}

export default IndexPage
